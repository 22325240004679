import '../icons/icons.css'
import React, { useState } from 'react';

const LoginPage = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ login, password }),
        });

        if (response.ok) {
            // Успешный ответ от сервера, можете обработать полученные данные или перенаправить пользователя
        } else {
            // Обработка ошибки, например показ сообщения об ошибке пользователю
        }
    };

    return (
        <div style={{ backgroundColor: "var(--cabin-main)", height: "100vh" }}>
            <div style={{ padding: "30px", margin: "auto", display: "flex" }}>
                <cabin-icon class="emblem-white-icon" style={{ margin: "auto" }}></cabin-icon>
            </div>

            <form onSubmit={handleSubmit}>
                <div style={{ margin: "auto", borderRadius: "20px", width: "500px", height: "fit-content", backgroundColor: "white" }}>
                    <div style={{ display: 'flex', flexDirection: "column", padding: "30px", fontSize: "20px", fontWeight: "bold" }}>
                        <p>EMAIL</p>
                        <input type="email" placeholder="Input email" value={login} onChange={(e) => setLogin(e.target.value)} />

                        <p>PASSWORD</p>
                        <input type="password" placeholder="Input password" style={{ marginBottom: "20px" }} value={password} onChange={(e) => setPassword(e.target.value)}/>

                        <div style={{ display: "flex" }}>
                            <input type="checkbox" style={{ width: "20px" }}></input>
                            <p style={{ margin: "auto 0 auto 5px" }}>REMEBER ME</p>
                        </div>

                        <div style={{ display: "flex" }}>
                            <button className='button-main'>LOGIN</button>
                            <a href='/reset_password' target='_blank' style={{ margin: "auto 10px auto auto" }}>FORGOT PASSWORD</a>
                        </div>

                        <div>
                            <p style={{ margin: "10px 0 0 0" }}>NO ACCOUNT?</p>
                            <a href='/register' target='_blank' style={{ margin: "auto 10px auto auto" }}>REGISTER</a>
                        </div>

                    </div>
                </div>
            </form>

        </div>
    )
}

export default LoginPage
